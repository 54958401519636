import {
	ContactSources,
	EmailEngagementSources,
	FormEngagementSources
} from 'utils/constants';
import {
	ConnectionTypes
} from 'utils/constants/connections';
import { addMinutes } from 'date-fns';

const CONNECTOR_V3_FEATURE_FLAG = 'user/isConnectorV3Enabled';

/**
 * @constant {String[]}
 */
const EngagementEventSourcesEligibleForContactCreation = Object.freeze([
	EmailEngagementSources.MAILCHIMP,
	EmailEngagementSources.ACTIVECAMPAIGN,
	EmailEngagementSources.KLAVIYO
]);

/**
 * @constant {String[]}
 */
const ContactSourcesEligibleForContactCreation = Object.freeze([
	ContactSources.PIPEDRIVE,
	ContactSources.HUBSPOT,
	ContactSources.COPPER
]);

/**
 * @param {Object} params
 * @param {Object} params.state
 * @param {Object} params.getters
 */
const isContactSyncTabVisible = ({ state, getters }) => {
	if (state.connection?.type) {
		return state.connection?.type === ConnectionTypes.CONTACT_SYNC;
	}

	return getters.areContactSyncRulesEnabled;
};

/**
 * @param {Object} params
 * @param {Object} params.state
 */
const isContactSyncFieldMappingTabVisible = ({ state }) => {
	if (state.connection?.type) {
		return state.connection?.type === ConnectionTypes.CONTACT_SYNC;
	}

	return false;
};

/**
 * @param {Object} params
 * @param {Object} params.state
 */
const isContactSyncDirectionTabVisible = ({
	state
}) => Boolean(state.connection?.contactSyncDirection);

/**
 * @param {Object} params
 * @param {Object} params.getters
 * @param {Object} params.rootGetters
 * @return {Boolean}
 */
const isContactCreationVisible = ({ getters }) =>
	EngagementEventSourcesEligibleForContactCreation.includes(getters.getEngagementEventSource)
	&& ContactSourcesEligibleForContactCreation.includes(getters.getContactSource);

/**
 * @param {Object} params
 * @param {Object} params.state
 * @param {Object} params.getters
 */
const isEngagementSyncTabVisible = ({ state, getters }) => {
	if (state.connection?.type) {
		return (state.connection?.type === ConnectionTypes.ENGAGEMENT_SYNC
			|| state.connection?.type === ConnectionTypes.FORM_SYNC);
	}

	if (getters.getContactSource === ContactSources.AIRTABLE && getters.isEmailToolConnection) {
		return false;
	}

	if (getters.isAdToolConnection) {
		return false;
	}

	return true;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isConnectionStatusSyncTabVisible = ({ getters, rootGetters }) => {
	if (getters.isAdToolConnection) {
		return false;
	}

	if (rootGetters[CONNECTOR_V3_FEATURE_FLAG] && getters.areContactSyncRulesEnabled) {
		return false;
	}

	return true;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isFormEngagementSyncVisible = ({ getters }) =>
	getters.isFormToolConnection || getters.isMeetingAppConnection;

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isEmailEngagementSyncVisible = ({ getters }) => getters.isEmailToolConnection;

/**
 * @param {Object} params
 * @param {Object} params.state
 * @param {Object} params.getters
 */
const isContactEventsComponentVisible = ({ state, getters }) => {
	if (state.connection?.type) {
		return state.connection?.type === ConnectionTypes.CONTACT_SYNC;
	}

	return !getters.isFormToolConnection
		&& !getters.isMeetingAppConnection;
};

/**
 * @param {Object} params
 * @param {Object} params.state
 * @param {Object} params.getters
 */
const isEngagementEventsComponentVisible = ({ state, getters }) => {
	if (state.connection?.type) {
		return state.connection?.type === ConnectionTypes.ENGAGEMENT_SYNC
			&& state.connection?.engagementEventSource !== EmailEngagementSources.KLAVIYO;
	}

	return !getters.isFormToolConnection
		&& !getters.isMeetingAppConnection;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isFormEventsComponentVisible = ({ getters }) => getters.isFormToolConnection;

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isMeetingEventsComponentVisible = ({ getters }) => getters.isMeetingAppConnection;

/**
 * @param {Object} params
 * @param {Object} params.getters
 * @param {Object} params.rootGetters
 */
const isDealCreationVisible = ({ getters, rootGetters }) => rootGetters['user/isDealCreationEnabled'] && !getters.isFormToolConnection && !getters.isMeetingAppConnection;

/**
 * @param {Object} params
 * @param {Object} params.rootGetters
 */
const isPreDealCreationSettingVisible = ({ rootGetters }) => rootGetters['user/isPipedriveLeadCreationEnabled'];

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isFormToolRecordEngagementComponentVisible = ({ getters }) => {
	if (getters.getContactSource === ContactSources.AIRTABLE) {
		return false;
	}

	return true;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isLeadIdentificationVisible = ({ getters }) => {
	if (getters.getEngagementEventSource === FormEngagementSources.FACEBOOK) {
		return false;
	}

	return true;
};

/**
 * @constant {Number}
 */
const SYNC_PERIOD_IN_MINUTES = 5;

/**
 * @param {{syncStartedAt: String}} params
 * @return {Boolean}
 */
const isSyncInProgress = ({ syncStartedAt }) => {
	if (!syncStartedAt) {
		return false;
	}

	const syncEndTimestamp = addMinutes(new Date(syncStartedAt), SYNC_PERIOD_IN_MINUTES);

	return new Date() < syncEndTimestamp;
};

/**
 * @param {{syncStartedAt: String}} params
 * @return {Number}
 */
const getSyncProgress = ({ syncStartedAt }) => {
	if (!syncStartedAt) {
		return 0;
	}

	const currentTimestamp = new Date().getTime();
	const syncStartedTimestamp = new Date(syncStartedAt).getTime();

	const syncPeriodInMs = SYNC_PERIOD_IN_MINUTES * 60 * 1000;

	const syncProgress = (((currentTimestamp - syncStartedTimestamp) / syncPeriodInMs) * 100)
		.toFixed(1);

	if (syncProgress > 100) {
		return 0;
	}

	return syncProgress;
};

/**
 * @param {Object} params
 * @param {Object} params.state
 */
const isEngagementSyncSettingsTabVisible = ({ state }) => {
	if (state.connection?.type) {
		return Boolean(state.connection?.type === ConnectionTypes.ENGAGEMENT_SYNC);
	}

	return false;
};

export {
	isEngagementSyncTabVisible,
	isFormEngagementSyncVisible,
	isContactSyncTabVisible,
	isContactSyncDirectionTabVisible,
	isEmailEngagementSyncVisible,
	isContactEventsComponentVisible,
	isEngagementEventsComponentVisible,
	isFormEventsComponentVisible,
	isMeetingEventsComponentVisible,
	isDealCreationVisible,
	isPreDealCreationSettingVisible,
	isContactCreationVisible,
	isFormToolRecordEngagementComponentVisible,
	isLeadIdentificationVisible,
	isSyncInProgress,
	getSyncProgress,
	isConnectionStatusSyncTabVisible,
	isContactSyncFieldMappingTabVisible,
	isEngagementSyncSettingsTabVisible
};
